/*--- MOBILE ---*/
.footer {
    text-align: center;
}


/*--- TABLET && DESKTOP ---*/
.footer {
    background-color: black;
    font-family: 'Brandon', sans-serif;
    font-size: 11px;
    color: white
}

.footer a{
    font-size: 22px;
    color: white;
    text-decoration: none;
}

.footer a:hover {
    color: rgba(255, 255, 255, 0.500);
}

.footer img {
    height: 15px;
    width: auto;
    margin: 5px 0 0 7px;
    margin-top: 0.7% !important;
}