/*--- MOBILE ---*/
@media (max-width: 600px) {
    .travelfleek-page .travelfleek-demo > #demo {
        margin-top: 20%;
        width: 100%; 
        max-height: 30%;
        object-Fit: contain;
        z-Index: 1 
    }
}

@media only screen and (min-width: 768px) {
    .travelfleek-page .travelfleek-demo > #demo {
        left: 50%;
        transform: translate(-50%,-50%);
        margin-top: 35%;
        position: relative;
        max-height: 20%;
        object-Fit: contain;
        z-Index: 1 
    }
}