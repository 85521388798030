@font-face {
    font-family: 'Brandon';
    src: url(../../utils/fonts/Brandon_reg.otf);
    font-style: normal;
    font-weight: 100;
}

.gallery {
    background-color: black;
    font-size: 2.0em;
    font-family: Brandon;
    color: white;
}
