/*--- MOBILE ---*/
@media (max-width: 600px) {
    .list {
        margin-top: -1px;
    }

    .list .work-progress .work-txt {
        width: 70vw;
        min-height: 60%;
    }

    .works {
        display: flex;
        flex-direction: column;
	    flex-wrap: nowrap;
        max-width: 100vw;
    }

    .works .links {
        display: flex;
        flex-direction: column;
        max-width: 100vw;
    }

    .links {
        min-width: 100vw;
    }
    .links img  {
        min-width: 200%;
        padding: 0 !important;
    }

    .links a img {
        min-width: 100%;
        padding-bottom: 100%;
    }

    .links a img {
        min-width: 200%;
    }
  }
  
/*--- TABLET & DESKTOP  ---*/
.header img  {
    width: 100vw;
    min-height: 10%;
}

.list .work-txt {
    width: 30vw;
    min-height: 10%;
}

.works {
    background-color: black;
}

.links img  {
    max-Width: 50%;
}

.links a  {
    max-Width: 50%;
}

    /* for image don't blink in hover */
.works img {
    min-width: 50%
}

/*--- HOVER EFFECT ON LINKS ---*/
.works .pumamayze:hover{
    content:url("../images/pumaMayze/PUMA_Mayze_rover.gif");
}

.works .pumaEMJ:hover{
    content:url('../images/pumaEMJ/ELAS\ MOVEM\ JUNTAS_.gif');
}

.works .pumacrystalline:hover{
    content:url('../images/pumaCrystalline/CRYSTALLINE_4x5_MASTER_022625_1.gif');
}

.works .puma:hover{
    content:url('../images/puma/puma-portfolio-home.gif');
}

.works .starry:hover{
    content:url('../images/starry/Starry_home.gif');
}

.works .pingoo:hover{
    content:url('../images/pingoo/pingoo-home-compressed.gif');
}

.works .rdm:hover{
    content:url('../images/rdm/rdm_rover_1.gif');
}

.works .travelFleek:hover{
    content:url('../images/travelFleek/rover_travel_fleek.gif');
}

.works .lumini:hover{
    content:url('../images/lumini/lumini_rover\ 2_1.gif');
}

.works .smc:hover{
    content:url('../images/smc/SMC_rover_1.gif');
}

.works .luca:hover{
    content:url('../images/luca/Luca_home.gif');
}

.works .rovers:hover{
    content:url('../images/rovers/Rover_home.gif');
}

.works .luanaLelis:hover{
    content:url('../images/luanaLelis/LL_home.gif');
}

.works .anis:hover{
    content:url('../images/anis/Anis_home_3.gif');
}
