@font-face {
    font-family: 'Brandon';
    src: url(../utils/fonts/Brandon_reg.otf);
    font-style: normal;
    font-weight: 100;
}

.header .menu {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
}

.header .menu button {
    background-color: #d1d1d1 !important;
    border-radius: 50px !important;
    /* width: 9em; */
    height: 2em;
    margin-top: -15%;
    z-index: 4;
    font-size: 1.7em;
    font-family: Brandon;
    color: white;
    margin-left: 3px !important;
    margin-right: 3px !important;
}

.header .menu button:hover {
    background-color: #ffffff !important;
    box-shadow: 0px 0px 15px 0px #ffffff;
}

.header .menu button:focus {
    background-color: #ffffff !important;
    box-shadow: 0px 0px 15px 0px #ffffff;
}

.arrow {
    position: absolute;
    display: flex;
    justify-content: end !important;
    right:1%;
    transform:translate(-50%, -50%);
    z-index: 9;
    width: 5.7% !important;
    height: 5.7% !important;
    margin-top: -7% !important;
}

.arrow {
	animation: slide-bottom 2.5s linear infinite both;
}

@keyframes slide-bottom {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(70%);
    }
  }

.btn{
    color: #383838 !important;
    font-size: 1.5em;
}