/*--- MOBILE ---*/
@font-face {
    font-family: 'Aboreto';
    src: url(../utils/fonts/Aboreto-Regular.ttf);
    font-style: normal;
    font-weight: 100;
}

.bg {
    font-size: 2em;
    font-family: Aboreto;
    color: white;
    position: absolute;
    z-index: 3;
}

.bg nav button {
    color:white;
    z-index: 3;
}

.bg nav a {
    color: white;
    text-decoration: none;
    z-index: 3;
}

.navbar-toggler-icon {
    color: white;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");;
}

/*--- TABLET & DESKTOP ---*/
.bg-desktop {
    font-size: 2.4em;
    font-family: Aboreto;
    position: absolute;
    z-index: 3;
}

.bg-desktop .nav {
    display: flex !important;
    justify-content: space-between !important;
}

.bg-desktop a {
    color: white;
    text-decoration: none;
    z-index: 3;
}

.bg-desktop a:hover {
    color: rgba(255, 255, 255, 0.500);
}

