/*--- MOBILE ---*/
@media (max-width: 600px) {
    .about-page  {
        width: 100vw;
        display: flex;
        flex-direction: column;
	    flex-wrap: nowrap;
        max-width: 100vw;
    }

    .about-page .fernada-img {
        width: 70%;
        margin: 20% 0
    }

    .about-page .col {
        width: 80vw ;
        margin: 0;
    }

    .tools-box img {
        width: 14% !important;
        height: auto
    }
    
    .tools-box .xd {
        width: 14.5% !important;
        height: auto
    }

    .clients-logo .logos img {
        padding: 0% !important;
    }

    .clients-logo .logos img {
        justify-content: baseline !important;
        width: 30%;
    }

    .contact-info {
        height: auto;
    }
    
    .contact-info a{
        font-size: 0.8em;
        margin-right: 20%;
    }

    .contact-cellphone img{
        margin-left: 5% !important;
    }

    #cel {
        width: auto !important;
        padding: 0% !important;
    }
    
    .contact-email img{
        margin-left: 7% !important
    }

    #email {
        width: 59% !important;
        padding: 0% !important;
    }
    
    .contact-linkedin img{
        margin-left: 7% !important
    }

    #linkedin {
        width: 59% !important;
        padding: 0% !important;
    }
}

/*--- TABLET ---*/
@media (min-width:601px) and (max-width:1024px)  {
    .about-page  {
        display: flex;
        flex-direction: column;
	    flex-wrap: nowrap;
        max-width: 100vw;
    }

    .about-page .fernada-img {
        width: 50%;
        margin: 15% 0
    }

    .about-page .col {
        width: 80vw ;
        margin: 0;
        font-size: 24px;
    }

    .tools-box img {
        width: 50% !important;
    }

    .contact-info {
        font-size: 24px;
    }
    .contact-logo  .contact-fefa-logo{
        margin-bottom: 40.5vh;
    }
    .contact-cellphone img{
        padding-left: 0% !important;
    }
    
    .contact-email img{
        padding-left: 0% !important;
    }
    
    .contact-linkedin img{
        padding-left: 0% !important;
    }

    .contact-logo img{
        margin-bottom: 20% !important;
    }
}


.about-page  {
    width: 100vw;
    margin: 0;
    padding-top: 2.5%;
    align-items: center;
}

.about-page img {
    border-radius: 5%;
    margin: 10%;
    width: 30%;
}

.about-page p {
    color: black;
    font-family: 'Brandon', sans-serif;
    font-size: 1.4em;
    width: 30%;
    height: auto;
    margin-left: 10%;
}

.about-page .caps {
    font-size: 1.7em;
}

.about-page .text {
    padding-top: 7%;
    /* margin-top: 2%; */
}

.skills button {
    background-color: #DDDD !important;
    border-radius: 50px !important;
    box-shadow: 0px 0px 15px 0px #ffffff;
    width: 5em;
    height: 2em;
    z-index: 4;
    font-size: 1.7em;
    font-family: Brandon;
    color: white;
}

.tools-box img {
    width: 7%;
    height: auto
}

.tools-box .xd {
    width: 7.5%;
    height: auto
}

.clients button {
    background-color: #DDDD !important;
    border-radius: 50px !important;
    box-shadow: 0px 0px 15px 0px #ffffff;
    width: 5em;
    height: 2em;
    z-index: 4;
    font-size: 1.7em;
    font-family: Brandon;
    color: white;
}

.contact button {
    background-color: #DDDD !important;
    border-radius: 50px !important;
    box-shadow: 0px 0px 15px 0px #ffffff;
    width: 5em;
    height: 2em;
    z-index: 4;
    font-size: 1.7em;
    font-family: Brandon;
    color: white;
}

.clients-logo img {
    width: 17%;
    height: auto
}

#atet {
    width: 18% !important;
    height: auto;
}

#cepam {
    width: 18% !important;
    height: auto;
}

#hypeEmpire {
    width: 18% !important;
    height: auto;
}

#latvc {
    width: 20% !important;
    height: auto;
}

#luca {
    width: 19% !important;
    height: auto;
}

#opticasPt {
    width: 23% !important;
    height: auto;
}

#puma {
    width: 13% !important;
    height: auto;
}

#rdm {
    width: 11%;
    height: auto;
}

#starry {
    width: 18%;
    height: auto;
}

#tf {
    width: 12%;
    height: auto;
}

.contact-info {
    color: white;
    text-align: center;
    font-family: 'Brandon', sans-serif;
    font-size: 1.2em;
    height: auto;
}

.contact-info a{
    color: black;
    margin-left: 30px;
    font-size: 1.1em;
    height: 20px;
}

/* .contact-name {
    font-family: 'Brandon';
    color: white;
    margin-top: 20%;
} */

.contact-cellphone img{
    width: 50px;
    max-height: 25px;
    margin-left: 38%;
}

.contact-email img{
    width: 50px;
    max-height: 25px;
    margin-left: 38%;
}

.contact-linkedin img{
    width: 50px;
    max-height: 25px;
    margin-left: 38%;
}